import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../../service/api'
import styled from 'styled-components'
import CarteiraListAtivos from './CarteiraListAtivos'
 

const DivListAtivos = styled.div`
width:100%;
`


const CarteiraGerenciamento = () => {

    let { id } = useParams()
    const [carteira, setCarteira] = useState(null)
    const [msgError, setMsgError] = useState(null)




    useEffect(() => {

        api.get(`/carteiras/${id}`)
            .then((resp) => {
                if (resp.data.success) {
                    setCarteira(resp.data.results)
                }


            }).catch((error) => {

                //setMsgError('Ocorreu um erro ao buscar os dados, por favor tente mais tarde')

            })
    }, [])


    return (
        <React.Fragment >
              <div className="box-white mt-10">
              <h5 className="titulo_pagina"> Carteira: <span className="text-primary">{(carteira != null) ? carteira.nome : ''}</span></h5> 
           
            </div>
            <div className="box-white mt-10">


            <CarteiraListAtivos idCarteira={id} />

</div>
        </React.Fragment>
    )

}
export default CarteiraGerenciamento