import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import api from '../../service/api';
import ButtonLink from '../layout/ButtonLink';
import CustomModalConfirm from '../layout/CustomModalConfirm';
import CarteiraService from '../../service/CarteiraService';
import { Button } from 'react-bootstrap'

const Carteira = () => {

    const [showConfirm, setShowConfirm] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [listCateiras, setListCarteiras] = useState(null)

    let { path, url } = useRouteMatch();


    const refreshCarteiras = () => {
        api.get('/carteiras').then((response) => {
            if (response.data.success) {
                setListCarteiras(response.data.results)
            }

        })
    }
    useEffect(() => {

        //console.log(CarteiraService.getAll())
        refreshCarteiras()


    }, [])

    const handleConfirmDelete = (idCarteira) => {
        console.log('sdf')
        setIdDelete(idCarteira)
        setShowConfirm(true)
    }
    const handleDelete = () => {



        api.delete('/carteiras/' + idDelete).then((response) => {
            if (response.data.success) {
                setIdDelete(null)
                setShowConfirm(false)
                refreshCarteiras()
            }

        })

    }

    return (
        <div>
            <div className="box-white">
                <h5 className="titulo_pagina">Carteiras   <ButtonLink variant="success" to={`${url}/cad`}>Novo</ButtonLink></h5>
            </div>
            <div className="box-white">
                <table className="table table-bordered table-stripped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {(listCateiras != null) ?
                            listCateiras.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <Link to={`${url}/${item.id}`}>
                                            {item.nome}
                                        </Link>
                                    </td>
                                    <td>
                                        <ButtonLink variant="primary" to={`${url}/edit/${item.id}`}>Editar</ButtonLink>

                                        <Button variant="danger" size={'sm'} onClick={() => handleConfirmDelete(item.id)} className="ml-5">Excluir</Button>
                                    </td>
                                </tr>
                            ))

                            : ''}

                    </tbody>


                </table>
            </div>

            <CustomModalConfirm show={showConfirm}
                btnCancel="Não"
                btnConfirm="Sim"
                handleClose={() => {
                    setShowConfirm(false)
                    setIdDelete(null)
                }}
                handleConfirm={handleDelete}
            >Tem certeza que deseja exculir esta carteira?</CustomModalConfirm>
        </div>
    )

}

export default Carteira