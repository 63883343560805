import api from "./api";

class MovimentoService {


    delete = (id) => {

        api.delete('movimentacoes/' + id)
            .then((resp) => {

                return resp;
            })
            .catch((er) => {
                return er
            })
    }


}

export default new MovimentoService();
