import { Formik, Form as FormikForm, Field, useField } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import NumberFormat from 'react-number-format'
import { moeda2float, dateBrToDb } from '../../../assets/utils'
import api from '../../../service/api'
import * as Yup from 'yup'
import consultaPtax from '../../../service/BcbOlindaPtax'


const CarteiraCadMov = (props) => {


    const [disabledEnviar, setDisabledEnviar] = useState(false)
    const [msgError, setMsgErro] = useState('')
    const [optionsAtivos, setOptionsAtivo] = useState([])
    const [optCorretora, setOptCorretora] = useState([])
    const [optTiposMov, setOptTipoMov] = useState([])
    const [errorForm, setErrorForm] = useState(null)
    const [ativoTipoDados, setAtivoTipoDados] = useState(null)
    const [tipoTxRede, setTipoTxRede] = useState(null)
    const [showDesdGrup, setShowDesdGrup] = useState(false)
    const [taxas, setTaxas] = useState({
        emolumentos: 0,
        taxaLiquidacao: 0,
        totalOperacao: 0,
        qntAtivoLiquido: 0
    })

    const [optAtivosCodPar, setOptAtivoCodPar] = useState([])
    const [optAtivosCodTaxa, setOptAtivoCodTaxa] = useState([])
    const [moedaParNome, setMoedaParNome] = useState('BRL')

    const [dadosItemSelected, setDadosItemSelected] = useState({
        ativoNome: '',
        ativoCodNome: '',
        corretoraNome: '',
        precoMedio: null,
        qnt: null
    })

    const [movimento, setMovimento] = useState({
        carteira_id: props.idCarteira,
        tipo_movimento_id: null,
        ativo_id: null,
        ativo_codigo_id: null,
        corretora_id: null,
        qnt: null,
        valor_unitario: null,
        data: '',
        hora: '',
        taxa_extra: '',
        taxa_rede: '',
        emolumentos: '',
        corretagem: '',
        taxa_liquidacao: '',
        permuta: '0',
        parAtivoCodId: '',
        parValorDolar: '',
        parCotacaoDolar: '',
        parQnt: '',
        comprovantes: null,
        observacoes: '',
        grupDesdDe: '',
        grupDesdPara: '',
        taxaCriptoAtivoCodId: null,
        taxaCriptoQnt: null,
        taxaCriptPercentual: null

    });

    const [isPermuta, setIsPermuta] = useState(false)

    const MovimentoSchema = Yup.object().shape({
        tipo_movimento_id: Yup.mixed().required('Selecione o tipo de movimento'),
        corretora_id: Yup.mixed().required('Selecione a corretora'),
      /*  qnt: Yup.mixed().required('Informe a quantidade'),
        valor_unitario: Yup.mixed().required('Informe o valor unitário'),
    */ data: Yup.string().required('Informa a data'),

    })


    useEffect(() => {



        if (props.idCarteiraItem) {
            api.get(`/carteiras/${props.idCarteira}/itens/${props.idCarteiraItem}`)
                .then((resp) => {
                    if (resp.data.success) {

                        let result = resp.data.results
                        setMovimento({
                            ...movimento,
                            ativo_id: result.ativo_id,
                            ativo_codigo_id: result.ativos_codigo_id,
                            corretora_id: result.corretora_id
                        })
                        console.log(result.corretora_id);
                        setDadosItemSelected({
                            ...dadosItemSelected,
                            ativoNome: result.ativo_nome,
                            ativoCodNome: result.ativos_codigo_nome,
                            corretoraNome: result.corretora_nome,
                            precoMedio: result.preco_medio,
                            qnt: result.qnt_total
                        })


                        getAtivoTipo(result.ativo_tipo_id, result.ativos_codigo_nome)
                    }
                })
        }


        api.get('/movimentosTipos')
            .then((resp) => {
                if (resp.data.success) {
                    setOptTipoMov(resp.data.results);
                }
            })

const t =  consultaPtax('2022-09-27').then((resp) =>{
    console.log(resp);
});
            console.log(t );

    }, [])



    const handleSearchCorretora = (query) => {

        api.get('/corretoras?search=' + query)
            .then((resp) => {
                if (resp.data.success) {
                    const options = resp.data.results.map((item) => ({
                        id: item.id,
                        label: item.nome
                    }))
                    setOptCorretora(options)
                } else {
                    setOptCorretora([])
                }
            }).catch((error) => {

                setOptCorretora([])
            })

    }

    const handleSearchAtivoCodPar = (query) => {
        let options = []
        api.get('/ativos?&ativos_tipo_id=3&search=' + query)
            .then((resp) => {


                if (resp.data.success) {
                    resp.data.results.map((item) => {

                        if (item.ativos_codigos.length > 0) {

                            item.ativos_codigos.map((rowCod) => {

                                options.push({
                                    id: rowCod.id,
                                    label: item.nome + ' - ' + rowCod.nome,
                                    ativo_id: item.id,
                                    ativo_tipo_id: item.ativos_tipo_id,
                                    ativo_codigo: rowCod.nome
                                })

                            })

                        }



                    })
                    console.log(options);

                    setOptAtivoCodPar(options)
                }

            })

    }

    const handleSearchAtivoCodTaxa = (query) => {
        let options = []
        api.get('/ativos?&ativos_tipo_id=3&search=' + query)
            .then((resp) => {


                if (resp.data.success) {
                    resp.data.results.map((item) => {

                        if (item.ativos_codigos.length > 0) {

                            item.ativos_codigos.map((rowCod) => {

                                options.push({
                                    id: rowCod.id,
                                    label: item.nome + ' - ' + rowCod.nome,
                                    ativo_id: item.id,
                                    ativo_tipo_id: item.ativos_tipo_id,
                                    ativo_codigo: rowCod.nome
                                })

                            })

                        }



                    })
                    console.log(options);

                    setOptAtivoCodTaxa(options)
                }

            })

    }

    const handleSearchAtivo = (query) => {

        let options2 = [];
        api.get('/ativos?search=' + query)
            .then((resp) => {
                if (resp.data.success) {


                    resp.data.results.map((item) => {
                        if (item.ativos_codigos != null) {
                            item.ativos_codigos.map((rowCod) => {
                                options2.push({
                                    id: rowCod.id,
                                    label: item.nome + ' - ' + rowCod.nome,
                                    ativo_id: item.id,
                                    ativo_tipo_id: item.ativos_tipo_id,
                                    ativo_codigo: rowCod.nome
                                })
                            })

                        }


                    })
                    // console.log(options2);
                    setOptionsAtivo(options2)
                }
            })
            .catch((error) => {

            })
    }




    const getAtivoTipo = (id, ativoCodigo = null) => {

        api.get('/ativosTipos/' + id).then((resp) => {
            if (resp.data.success) {
                setAtivoTipoDados(resp.data.results)
                let ativoTipo = resp.data.results;

                if (ativoTipo.possui_taxa_rede == 1) {
                    setTipoTxRede(ativoCodigo)
                }
                setAtivoTipoDados(resp.data.results)
            }

        })

    }

    const handleChangeTypeHead = (selected, tipo, setFieldValue) => {

console.log(selected);
        if (selected.length > 0) {
            setFieldValue(tipo, selected[0].id)
        }
        switch (tipo) {
            case 'ativo_id':
                if (selected.length > 0) {
                    setFieldValue('ativo_id', selected[0].ativo_id)
                    setFieldValue('ativo_codigo_id', selected[0].id)
                    getAtivoTipo(selected[0].ativo_tipo_id, selected[0].ativo_codigo)
                }
                break;

            case 'parAtivoCodId':

                if (selected.length > 0) {
                    setFieldValue('parAtivoCodId', selected[0].id)
                    setMoedaParNome(selected[0].ativo_codigo)
                } else {
                    setMoedaParNome(null)
                }
                break;

            case 'taxaCriptoAtivoCodId':

                if (selected.length > 0) {
                   
                    setFieldValue('taxaCriptoAtivoCodId', selected[0].id)
                     console.log(selected[0].id)

                }
                break;


        }


    }




    const handleBlurValorTotal = (e, values) => {

        console.log(values);
        let totalOperacao = 0;
        let qnt = (values.qnt != null) ? moeda2float(values.qnt) : '';
        let valor_unitario = (values.valor_unitario != null) ? moeda2float(values.valor_unitario) : '';
        let emolumentos = 0;
        let taxaLiquidacao = 0;


        if (qnt > 0 && valor_unitario > 0) {
            totalOperacao = qnt * valor_unitario

            if (ativoTipoDados != null && ativoTipoDados.possui_emolumentos == 1) {
                emolumentos = totalOperacao * (ativoTipoDados.percentual_emolumentos / 100)
            }

            if (ativoTipoDados != null && ativoTipoDados.possui_taxa_liquidacao == 1) {
                taxaLiquidacao = totalOperacao * (ativoTipoDados.percentual_liquidacao / 100)
            }




            console.log(ativoTipoDados);
            console.log(totalOperacao);
            setTaxas({
                ...taxas,
                totalOperacao: totalOperacao,
                emolumentos: emolumentos,
                taxaLiquidacao: taxaLiquidacao
            })

        } else {

        }



    }



    const handleChangeNumberFormat = (valueNumberFormat, fieldName, setFieldValue) => {
        setFieldValue(fieldName, valueNumberFormat.formattedValue)

    }

    const handleChangePermuta = (setFieldValue, valor) => {

        setIsPermuta(valor)
        setFieldValue('permuta', valor)
        if (valor == 0) {
            setFieldValue('parAtivoCodId', null)
            setFieldValue('parValorDolar', null)
            setFieldValue('parCotacaoDolar', null)
            setFieldValue('parQnt', null)
            setMoedaParNome('BRL')
        }

    }

    const handleChangeTipoMovimento = (e, setFieldValue) => {


        setFieldValue('tipo_movimento_id', e.target.value)

        if (e.target.value == '6' || e.target.value == '7') {
            setShowDesdGrup(true)
        } else {
            setShowDesdGrup(false)
        }


    }


    const handleSubmit = (values, actions) => {
        console.log(values);
        let formData = new FormData();

        if (values.comprovantes != null) {
            for (let i = 0; i < values.comprovantes.length; i++) {
                formData.append("comprovantes[]", values.comprovantes[i]);
            }
        }


        formData.append("ativo_codigo_id", values.ativo_codigo_id);
        formData.append("ativo_id", values.ativo_id);
        formData.append("carteira_id", values.carteira_id);
        formData.append("corretagem", (values.corretagem != '') ? moeda2float(values.corretagem) : '');
        formData.append("corretora_id", values.corretora_id);

        formData.append("data", (values.data != '') ? dateBrToDb(values.data) : null);
        formData.append("hora", values.hora);

        formData.append("tipo_movimento_id", values.tipo_movimento_id);
        formData.append("parAtivoCodId", values.parAtivoCodId);
        formData.append("permuta", values.permuta);
        formData.append("observacoes", values.observacoes);

        formData.append("qnt", (values.qnt != null) ? moeda2float(values.qnt) : '');
        formData.append("emolumentos", (values.emolumentos != '') ? moeda2float(values.emolumentos) : '');

        formData.append("parCotacaoDolar", (values.parCotacaoDolar != '') ? moeda2float(values.parCotacaoDolar) : '');
        formData.append("parQnt", (values.parQnt != '') ? moeda2float(values.parQnt) : '');
        formData.append("parValorDolar", (values.parValorDolar != '') ? moeda2float(values.parValorDolar) : '');
        formData.append("taxa_extra", (values.taxa_extra != '') ? moeda2float(values.taxa_extra) : '');

        formData.append("taxa_liquidacao", (values.taxa_liquidacao != '') ? moeda2float(values.taxa_liquidacao) : '');
        formData.append("taxa_rede", (values.taxa_rede != '') ? moeda2float(values.taxa_rede) : '');
        formData.append("valor_unitario", (values.valor_unitario != null) ? moeda2float(values.valor_unitario) : '');


        formData.append("grupDesdDe", (values.grupDesdDe != '') ? moeda2float(values.grupDesdDe) : '');
        formData.append("grupDesdPara", (values.grupDesdPara != '') ? moeda2float(values.grupDesdPara) : '');

        formData.append("taxaCriptoAtivoCodId", (values.taxaCriptoAtivoCodId != null) ? moeda2float(values.taxaCriptoAtivoCodId) : '');
        formData.append("taxaCriptoQnt", (values.taxaCriptoQnt != null) ? moeda2float(values.taxaCriptoQnt) : '');
        formData.append("taxaCriptPercentual", (values.taxaCriptPercentual != null) ? moeda2float(values.taxaCriptPercentual) : '');

        console.log(formData);
        /* let dadosForm = {
             ...values,
 
             qnt: moeda2float(values.qnt),
             valor_unitario: moeda2float(values.valor_unitario),
             taxa_extra: (values.taxa_extra != '') ? moeda2float(values.taxa_extra) : 0,
             taxa_rede: (values.taxa_rede != '') ? moeda2float(values.taxa_rede) : 0,
             emolumentos: (values.emolumentos != '') ? moeda2float(values.emolumentos) : 0,
             corretagem: (values.corretagem != '') ? moeda2float(values.corretagem) : 0,
             taxa_liquidacao: (values.taxa_liquidacao != '') ? moeda2float(values.taxa_liquidacao) : 0,
             parValorDolar: (values.parValorDolar != '') ? moeda2float(values.parValorDolar) : 0,
 
             parCotacaoDolar: (values.parCotacaoDolar != '') ? moeda2float(values.parCotacaoDolar) : 0,
             parQnt: (values.parQnt != '') ? moeda2float(values.parQnt) : 0,
             data: (values.data != '') ? dateBrToDb(values.data) : null
         };*/


        setDisabledEnviar(true)

        api.post('/movimentacoes', formData)
            .then((resp) => {
                if (resp.data.success) {

                    props.handleCancel()
                    props.refreshCarteiraItens(props.idCarteira);

                } else {
                    console.log(resp.data);
                }

            }).catch((error, resp) => {

                if (error.response.data.errors.length > 0) {
                    setMsgErro(error.response.data.errors[0])
                }
                console.log(error.response.data);
                setDisabledEnviar(false)

            })


    }

    return (
        <React.Fragment>
            <h5 className="text-primary">Lançar movimento</h5>
            <hr></hr>

            <Formik
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={movimento}
                validationSchema={MovimentoSchema}
                render={({ values, setFieldValue, errors }) => (
                    <FormikForm>

                        {(props.idCarteiraItem != null) ? (
                            <div>
                                <h5 className="text-danger">
                                    <span>{dadosItemSelected.ativoCodNome} - {dadosItemSelected.ativoNome} </span>
                                    | {dadosItemSelected.corretoraNome}
                                </h5>
                                <label>Preço médio: </label>
                                <NumberFormat
                                    className="ml-5"
                                    decimalScale={(ativoTipoDados) ? ativoTipoDados.casas_decimais_qnt : 0}
                                    decimalSeparator=','
                                    displayType='text'
                                    isNumericString={true}
                                    value={dadosItemSelected.precoMedio}
                                />
                                <br></br>
                                <label>Qnt: </label>
                                <NumberFormat
                                    className="ml-5"
                                    decimalScale={(ativoTipoDados) ? ativoTipoDados.casas_decimais_qnt : 0}
                                    decimalSeparator=','
                                    displayType='text'
                                    isNumericString={true}
                                    value={dadosItemSelected.qnt}
                                />


                            </div>

                        ) : ''}


                        {(props.idCarteiraItem == null) ? (
                            <Row>
                                <Col sm={12}>
                                    <Form.Label>Ativo</Form.Label>
                                    <AsyncTypeahead
                                        filterBy={() => true}
                                        name="ativo_id"
                                        id="basic-typeahead-single"
                                        onSearch={handleSearchAtivo}
                                        options={optionsAtivos}
                                        placeholder="Digite o nome ou código do  ativo"
                                        selected={values.ativoSelected}
                                        onChange={(selected) => handleChangeTypeHead(selected, 'ativo_id', setFieldValue)}
                                    />
                                </Col>


                            </Row>
                        ) : ''}

                        <div style={(ativoTipoDados == null && props.idCarteiraItem == null) ? { display: 'none' } : { display: '' }}>

                            <br></br>
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>Data: </Form.Label>
                                    <NumberFormat
                                        name="data"
                                        value={values.data}
                                        format={'##/##/####'}
                                        onChange={(e) => setFieldValue('data', e.target.value)}
                                        className="form-control"
                                    />
                                    {(errors.data) ? (
                                        <span className="text-danger">{errors.data}</span>
                                    ) : null}
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Hora: </Form.Label>


                                    <NumberFormat
                                        name="hora"
                                        value={values.hora}
                                        format={(ativoTipoDados && ativoTipoDados.id == 3) ? '##:##:##' : '##:##'}
                                        onChange={(e) => setFieldValue('hora', e.target.value)}
                                        className="form-control"
                                    />

                                </Col>

                            </Row>
                            <br></br>
                            <Row>
                                {(props.idCarteiraItem == null) ? (
                                    <Col sm={6} >
                                        <Form.Label>Corretora:</Form.Label>
                                        <AsyncTypeahead
                                            filterBy={() => true}
                                            onSearch={handleSearchCorretora}
                                            name="corretora_id"
                                            id="corretora_id"
                                            options={optCorretora}
                                            placeholder="Corretora"
                                            onChange={(selected) => handleChangeTypeHead(selected, 'corretora_id', setFieldValue)}

                                        />
                                        {(errors.corretora_id) ? (
                                            <span className="text-danger">{errors.corretora_id}</span>
                                        ) : null}
                                    </Col>
                                ) : ''}
                                <Col sm={6} >
                                    <Form.Label>Movimento:</Form.Label>
                                    <Field component="select" name="tipo_movimento_id" className="form-select  input-sm" onChange={(e) => handleChangeTipoMovimento(e, setFieldValue)}>
                                        <option value="">Selecione...</option>
                                        <option value=""></option>
                                        {(optTiposMov != null) ?


                                            optTiposMov.map((item, i) => (
                                                <option value={item.id}>{item.nome}</option>
                                            ))
                                            : ''}
                                    </Field>
                                    {(errors.tipo_movimento_id) ? (
                                        <span className="text-danger">{errors.tipo_movimento_id}</span>
                                    ) : null}
                                </Col>
                            </Row>
                            <br></br>


                            <div>



                                {(showDesdGrup) ? (
                                    <div>
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>De:</Form.Label>
                                                <NumberFormat
                                                    className="form-control input-sm"
                                                    decimalSeparator=","
                                                    decimalScale={0}
                                                    name="grupDesdDe"
                                                    onValueChange={(value) => handleChangeNumberFormat(value, 'grupDesdDe', setFieldValue)}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Label>Para:</Form.Label>
                                                <NumberFormat
                                                    className="form-control input-sm"
                                                    decimalSeparator=","
                                                    decimalScale={0}
                                                    name="grupDesdPara"
                                                    onValueChange={(value) => handleChangeNumberFormat(value, 'grupDesdPara', setFieldValue)}
                                                />
                                            </Col>
                                        </Row>
                                        <br></br>
                                    </div>


                                ) : (

                                    <div>

                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>Qnt.{(tipoTxRede != null) ? '(' + tipoTxRede + ')' : ''}:</Form.Label>
                                                <NumberFormat
                                                    className="form-control input-sm"
                                                    decimalSeparator=","
                                                    decimalScale={(ativoTipoDados) ? ativoTipoDados.casas_decimais_qnt : 0}
                                                    name="qnt"
                                                    onValueChange={(value) => handleChangeNumberFormat(value, 'qnt', setFieldValue)}
                                                    onBlur={(e) => handleBlurValorTotal(e, values)}
                                                />
                                                {(errors.qnt) ? (
                                                    <span className="text-danger">{errors.qnt}</span>
                                                ) : null}
                                            </Col>




                                            {(ativoTipoDados != null && ativoTipoDados.possui_taxa_rede == 1) ?
                                                <React.Fragment>

                                                    <Col sm={6}>
                                                        <Form.Label>Taxa de rede {(tipoTxRede != null) ? '(' + tipoTxRede + ')' : ''}
                                                        </Form.Label>
                                                        <NumberFormat
                                                            className="form-control input-sm"
                                                            decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                            decimalSeparator=","
                                                            name="taxa_rede"
                                                            value={values.taxa_rede}
                                                            onValueChange={(value) => handleChangeNumberFormat(value, 'taxa_rede', setFieldValue)}
                                                            onBlur={(e) => handleBlurValorTotal(e, values)}
                                                        />
                                                    </Col>
                                                </React.Fragment>

                                                : ''}


                                        </Row>
                                        <br></br>
                                        {(ativoTipoDados != null && ativoTipoDados.id == 3) ?
                                            <div>

                                                <Row className="mb-3">
                                                    <Col sm={6}>
                                                        <label className="mr-15">Permuta?</label>
                                                        <Field type="radio" name="permuta" checked={(values.permuta == 0)} onChange={() => handleChangePermuta(setFieldValue, 0)} /> Não
                                                        <Field type="radio" name="permuta" checked={(values.permuta == 1)} className="ml-15" onChange={() => handleChangePermuta(setFieldValue, 1)} /> Sim
                                                    </Col>
                                                </Row>


                                                {(isPermuta) ?
                                                    <React.Fragment>
                                                        <h6 className="text-default mb-3">Moeda de compra</h6>
                                                        <Row className="mb-3">
                                                            <Col sm={6}>
                                                                <label>Par do Ativo</label>
                                                                <AsyncTypeahead
                                                                    filterBy={() => true}
                                                                    onSearch={handleSearchAtivoCodPar}
                                                                    name="parAtivoCodId"
                                                                    options={optAtivosCodPar}
                                                                    onChange={(selected) => handleChangeTypeHead(selected, 'parAtivoCodId', setFieldValue)}

                                                                />
                                                            </Col>
                                                            <Col sm={6} style={(values.parAtivoCodId == 807 || values.parAtivoCodId == 816) ? { display: 'none' } : null}>
                                                                <label>Cotação (<span style={{ color: '#2196f3' }}>{moedaParNome}</span>) em dólar</label>
                                                                <NumberFormat
                                                                    className="form-control input-sm"
                                                                    decimalSeparator=","
                                                                    decimalScale={(ativoTipoDados) ? ativoTipoDados.casas_decimais_qnt : 0}
                                                                    name="parValorDolar"
                                                                    onValueChange={(value) => handleChangeNumberFormat(value, 'parValorDolar', setFieldValue)}
                                                                    onBlur={(e) => handleBlurValorTotal(e, values)}
                                                                />
                                                                {(errors.parValorDolar) ? (
                                                                    <span className="text-danger">{errors.parValorDolar}</span>
                                                                ) : null}
                                                            </Col>


                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm={6}>
                                                                <label>Cotação do dólar</label>
                                                                <NumberFormat
                                                                    className="form-control input-sm"
                                                                    decimalSeparator=","
                                                                    decimalScale={(ativoTipoDados) ? ativoTipoDados.casas_decimais_qnt : 0}
                                                                    name="parCotacaoDolar"
                                                                    onValueChange={(value) => handleChangeNumberFormat(value, 'parCotacaoDolar', setFieldValue)}
                                                                    onBlur={(e) => handleBlurValorTotal(e, values)}
                                                                />
                                                                {(errors.parValorDolar) ? (
                                                                    <span className="text-danger">{errors.parCotacaoDolar}</span>
                                                                ) : null}
                                                            </Col>
                                                        </Row>

                                                        <div style={{ fontSize: 12, color: '#817a7a', fontWeight: 'bold' }}>
                                                            ** A 'Cotação do par em dólar' é valor de fechamento da cripto no dia<br></br>
                                                            ** A 'Cotação do dólar' utilizada é a P-Tax do Banco Central
                                                        </div>

                                                        <hr></hr>
                                                    </React.Fragment> : ''}

                                            </div>
                                            : ''}

                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>Valor Unitário
                                                    <span style={{ color: '#2196f3' }}>
                                                        {(tipoTxRede != null) ? ' ' + tipoTxRede + '/' : ''}
                                                        {(moedaParNome != null) ?  moedaParNome  : ''} :
                                                    </span>
                                                </Form.Label>
                                                <NumberFormat
                                                    className="form-control input-sm"
                                                    decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                    decimalSeparator=","
                                                    name="npm stario"
                                                    value={values.valor_unitario}
                                                    onValueChange={(value) => handleChangeNumberFormat(value, 'valor_unitario', setFieldValue)}
                                                    onBlur={(e) => handleBlurValorTotal(e, values)}
                                                />
                                                {(errors.valor_unitario) ? (
                                                    <span className="text-danger">{errors.valor_unitario}</span>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            {(ativoTipoDados != null && ativoTipoDados.possui_emolumentos == 1) ?
                                                <Col sm={6}>
                                                    <Form.Label>Emolumentos: <i title="Também conhecido com taxa de negociação">?</i></Form.Label>
                                                    <NumberFormat
                                                        className="form-control input-sm"
                                                        decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                        decimalSeparator=","
                                                        name="emolumentos"
                                                        value={values.emolumentos}
                                                        onValueChange={(value) => handleChangeNumberFormat(value, 'emolumentos', setFieldValue)}
                                                        onBlur={(e) => handleBlurValorTotal(e, values)}
                                                    />

                                                </Col>
                                                : ''}
                                            {(ativoTipoDados != null && ativoTipoDados.possui_taxa_liquidacao == 1) ?
                                                <Col sm={6}>
                                                    <Form.Label>Taxa de liquidação: <i title="Também conhecido com taxa de negociação">?</i></Form.Label>

                                                    <NumberFormat
                                                        className="form-control input-sm"
                                                        decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                        decimalSeparator=","
                                                        name="taxa_liquidacao"
                                                        value={values.taxa_liquidacao}
                                                        onValueChange={(value) => handleChangeNumberFormat(value, 'taxa_liquidacao', setFieldValue)}
                                                        onBlur={(e) => handleBlurValorTotal(e, values)}
                                                    />
                                                </Col>
                                                : ''}

                                            {(ativoTipoDados != null && ativoTipoDados.possui_corretagem == 1) ?
                                                <Col sm={6}>
                                                    <Form.Label>Corretagem: </Form.Label>
                                                    <NumberFormat
                                                        className="form-control input-sm"
                                                        decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                        decimalSeparator=","
                                                        name="corretagem"
                                                        value={values.corretagem}
                                                        onValueChange={(value) => handleChangeNumberFormat(value, 'corretagem', setFieldValue)}
                                                        onBlur={(e) => handleBlurValorTotal(e, values)}
                                                    />
                                                </Col>

                                                : ''}
                                            {(ativoTipoDados != null && ativoTipoDados.possui_taxa_extra == 1) ?


                                                <Col sm={6}>
                                                    <Form.Label>Taxa Extra: </Form.Label>
                                                    <NumberFormat
                                                        className="form-control input-sm"
                                                        decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                        decimalSeparator=","
                                                        name="taxa_extra"
                                                        value={values.taxa_extra}
                                                        onValueChange={(value) => handleChangeNumberFormat(value, 'taxa_extra', setFieldValue)}
                                                        onBlur={(e) => handleBlurValorTotal(e, values)}
                                                    />
                                                </Col>


                                                : ''}




                                        </Row>

                                        {(ativoTipoDados != null && ativoTipoDados.possui_taxa_rede == 1 && (
                                            values.tipo_movimento_id == 2)) ? (
                                            <div> <br></br>
                                                <Row>


                                                    <Col sm={6}>
                                                        <Form.Label>Taxa em criptomoeda
                                                        </Form.Label>
                                                        <AsyncTypeahead
                                                            id="taxaCriptoAtivoCodId"
                                                            filterBy={() => true}
                                                            onSearch={handleSearchAtivoCodTaxa}
                                                            name="taxaCriptoAtivoCodId"
                                                            options={optAtivosCodTaxa}
                                                            onChange={(selected) => handleChangeTypeHead(selected, 'taxaCriptoAtivoCodId', setFieldValue)}

                                                        />
                                                    </Col>

                                                    <Col sm={6}>
                                                        <Form.Label>Qnt de criptomoeda
                                                        </Form.Label>
                                                        <NumberFormat
                                                            className="form-control input-sm"
                                                            decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                            decimalSeparator=","
                                                            name="taxaCriptoQnt"
                                                            value={values.taxaCriptoQnt}
                                                            onValueChange={(value) => handleChangeNumberFormat(value, 'taxaCriptoQnt', setFieldValue)}

                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>Percentual da taxa
                                                        </Form.Label>
                                                        <NumberFormat
                                                            className="form-control input-sm"
                                                            decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                                            decimalSeparator=","
                                                            name="taxaCriptPercentual"
                                                            value={values.taxaCriptPercentual}
                                                            onValueChange={(value) => handleChangeNumberFormat(value, 'taxaCriptPercentual', setFieldValue)}
                                                            onBlur={(e) => handleBlurValorTotal(e, values)}
                                                        />
                                                    </Col>

                                                </Row>
                                            </div>
                                        ) : ''}


                                        <br></br>
                                    </div>

                                )}

                                <br></br>


                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>Comprovante/Nota de negociação</Form.Label>
                                        <input type="file" name='comprovantes' multiple onChange={(e) => setFieldValue('comprovantes', e.target.files)} />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row className="mb-3">
                                    <Col sm={12}>
                                        <label className="mr-15">Observações:</label>
                                        <Field as="textarea" name="observacoes" value={values.observacoes} className="form-control input-sm" />
                                    </Col>
                                </Row>
                                <br></br>



                                {(ativoTipoDados != null && ativoTipoDados.possui_emolumentos == 1) ? (
                                    <div className="bold text-secondary">Emolumentos
                                        <NumberFormat
                                            isNumericString={true}
                                            decimalSeparator=','
                                            decimalScale={3}
                                            displayType="text"
                                            value={(ativoTipoDados != null && ativoTipoDados.possui_emolumentos == 1) ? ativoTipoDados.percentual_emolumentos : ''}
                                            prefix="("
                                            suffix="%)"
                                            className="ml-5"
                                        />:
                                        <NumberFormat
                                            isNumericString={true}
                                            value={taxas.emolumentos}
                                            decimalSeparator=','
                                            decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                            displayType="text"
                                            prefix=" R$ "
                                            fixedDecimalScale={true}

                                        />
                                    </div>
                                )
                                    : ''}
                                <br></br>
                                {(ativoTipoDados != null && ativoTipoDados.possui_taxa_liquidacao == 1) ? (

                                    <div className="bold text-secondary">Taxa de Liquidação
                                        <NumberFormat
                                            isNumericString={true}
                                            decimalSeparator=','
                                            decimalScale={3}
                                            displayType="text"
                                            value={(ativoTipoDados != null && ativoTipoDados.possui_taxa_liquidacao == 1) ? ativoTipoDados.percentual_liquidacao : ''}
                                            prefix="("
                                            suffix="%)"
                                            className="ml-5"
                                        />:

                                        <NumberFormat
                                            isNumericString={true}
                                            value={taxas.taxaLiquidacao}
                                            decimalSeparator=','
                                            decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                            displayType="text"
                                            prefix=" R$ "
                                            fixedDecimalScale={true}
                                        />
                                    </div>
                                )
                                    : ''}

                                <div className="bold text-secondary">Total da operação:

                                    <NumberFormat
                                        isNumericString={true}
                                        value={taxas.totalOperacao}
                                        decimalSeparator=','
                                        decimalScale={(ativoTipoDados != null) ? ativoTipoDados.casas_decimais_preco : 2}
                                        displayType="text"
                                        prefix=" R$ "
                                        fixedDecimalScale={true}

                                    />
                                </div>

                                {(ativoTipoDados != null && ativoTipoDados.possui_taxa_rede == 1) ?
                                    <div className="bold text-secondary">Quantidade do ativo líquido: <span className="text-secondary"> R$ {taxas.qntAtivoLiquido}</span></div>
                                    : ''}

                            </div>


                        </div>
                        <br></br>


                        {
                            (msgError != '') ? (
                                <div className='alert alert-danger'>{msgError}</div>) : ''
                        }
                        <div className="text-center">
                            <Button variant="danger" size="sm" onClick={props.handleCancel}>Cancelar</Button>
                            <Button type="submit" variant="primary" size="sm" className="ml-5" disabled={disabledEnviar} style={(ativoTipoDados == null) ? { display: 'none' } : { display: '' }}>Lançar</Button>
                        </div>
                    </FormikForm>

                )
                }
            >

            </Formik >



        </React.Fragment >
    )

}
export default CarteiraCadMov