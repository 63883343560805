import axios from "axios";


export function currencyFormatter(value, minimumFractionDigits, prefix) {
   console.log(value);
   if (!Number(value)) return "";



   let amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: minimumFractionDigits
   }).format(value / 100)



   return `${amount}`;
}


export function currencyFormatter2(value, minimumFractionDigits, prefix) {
   console.log(value);
   if (!Number(value)) return "";



   let amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: minimumFractionDigits
   }).format(value)



   return `${amount}`;
}


export function float2moeda(num) {
   let x = 0;

   if (num < 0) {
      num = Math.abs(num);
      x = 1;
   }
   if (isNaN(num)) num = "0";
   let cents = Math.floor((num * 100 + 0.5) % 100);

   num = Math.floor((num * 100 + 0.5) / 100).toString();

   if (cents < 10) cents = "0" + cents;
   for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num = num.substring(0, num.length - (4 * i + 3)) + '.'
         + num.substring(num.length - (4 * i + 3));
   let ret = num + ',' + cents;
   if (x == 1) ret = ' - ' + ret; return ret;

}

export function moeda2float(moeda) {
   moeda = moeda.replace(".", "");
   moeda = moeda.replace(",", ".");
   return parseFloat(moeda);

}

export function dateBrToDb(data) {

   data = data.split('/')
   data = data[2] + '-' + data[1] + '-' + data[0]
   return data;
}

export function dateDBToBR(data) {

   data = data.split('-')
   data = data[2] + '/' + data[1] + '/' + data[0]
   return data;
}

export function mesesList(){
let meses = [
   { 'id': '01','nome' :'Janeiro'},
   { 'id': '02','nome' :'Fevereiro'},
   { 'id': '03','nome' :'Março'},
   { 'id': '04','nome' :'Abril'},
   { 'id': '05','nome' :'Maio'},
   { 'id': '06','nome' :'Junho'},
   { 'id': '07','nome' :'Julho'},
   { 'id': '08','nome' :'Agosto'},
   { 'id': '09','nome' :'Setembro'},
   { 'id': '10','nome' :'Outubro'},
   { 'id': '11','nome' :'Novembro'},
   { 'id': '12','nome' :'Dezembro'},
   ]
   
return meses;
}


export function anosList(inicio=1990){
 //  let anoAtual = new Date().getFullYear()

   //for(i=inicio;i>)
}

