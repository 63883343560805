
import { Formik, Form as FormFormik, Field } from 'formik';
import React, { useState } from 'react';
import { Col, Form, FormLabel, Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup'
import AuthService from '../service/AuthService';



const DivLogin = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:100vh


`
const DivForm = styled.div`
width:50%;
padding:15px;
background:#FFF;
`

const {REACT_APP_AMBIENTE} = process.env

console.log(process.env);

const Login = () => {


    let history = useHistory()
    const [dadosForm, setDadosForm] = useState({
        email: '',
        senha: ''
    })
    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('Digite seu e-mail'),
        senha: Yup.string().min(1).required('Digite sua senha')
    })


    const handleSubmit = async (values, actions) => {

        const logar = await AuthService.validateLogin(values.email, values.senha)

        if (logar) {
            history.push('/')
        }

    }

    return (
        <React.Fragment>

            <DivLogin>
                <DivForm>
                    <h4 className="text-center">Login</h4>

                    <Formik
                        validationSchema={LoginSchema}
                        onSubmit={handleSubmit}
                        initialValues={dadosForm}
                        render={({ values, errors }) => (

                            <FormFormik>

                                <Row>
                                    <Col sm={12}>
                                        <FormLabel>E-mail:</FormLabel>
                                        <Field type="text" name="email" className="form-control" />
                                        {(errors.email) ? <div className="text-danger">{errors.email}</div> : ''}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} className="mt-10">
                                        <FormLabel>Senha:</FormLabel>
                                        <Field type="password" name="senha"  className="form-control" />
                                        {(errors.senha) ? <div className="text-danger">{errors.senha}</div> : ''}
                                    </Col>
                                </Row>
                                <div className="text-center mt-10">
                                    <Button variant="primary" type="submit">Entrar</Button>
                                </div>

                            </FormFormik>

                        )}
                    >



                    </Formik>
                </DivForm>
            </DivLogin>

        </React.Fragment>

    )

}

export default Login;