import React, { useEffect, useState } from 'react'
import { Col, FormLabel, Row, Table, Field, Form, Alert, Button } from 'react-bootstrap'
import CustomCard from '../layout/CustomCard'
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead'
import api from '../../service/api'
import { mesesList, dateDBToBR } from '../../assets/utils'
import NumberFormat from 'react-number-format'

const CustodiaAnual = () => {

    const [listAnos, setListAnos] = useState([])
    const [listDados, setListDados] = useState(null)
    const [msgError, setMsgErro] = useState('')
    const [formFiltro, setFormFiltro] = useState({
        mes: (new Date().getMonth()) + 1,
        ano: (new Date().getFullYear()),
        tipoAtivoId: '',
        corretoraId: ''
    })
    const [dadosInfo, setDadosInfo] = useState({
        totalVendas: 0,
        totalPrejuizo: 0,
        totalLucro: 0
    })

    const [optionsCorretora, setOptionsCorretora] = useState([])

    useEffect(() => {
        api.get('/corretoras?movimentacao=true')
            .then((resp) => {
                if (resp.data.success) {
                    const options = resp.data.results.map((item) => ({
                        id: item.id,
                        nome: item.nome
                    }))

                    setOptionsCorretora(options)
                    optionsYears()
                }
            })
    }, [])


    const handleChange = (e) => {


        switch (e.target.name) {

            case 'mes': setFormFiltro({ ...formFiltro, mes: e.target.value })
                break;

            case 'ano': setFormFiltro({ ...formFiltro, ano: e.target.value })
                break;
            case 'corretora': setFormFiltro({ ...formFiltro, corretoraId: e.target.value })
                break;
        }
        setMsgErro('')
    }

    const optionsYears = () => {

        let ano = new Date();
        let anos = [];
        for (let i = ano.getFullYear(); i > 2000; i--) {
            anos.push(i)
        }
        console.log(anos)
        setListAnos(anos)

    }

    const handleFiltrar = () => {

        if (formFiltro.mes === '') {
            setMsgErro('O mes deve ser selecionado.')
            return false
        }
        if (formFiltro.ano === '') {
            setMsgErro('O ano deve ser selecionado.')
            return false
        }

        if (formFiltro.corretoraId === '') {
            setMsgErro('A corretora deve ser selecionada.')
            return false
        }

        api.get(`/relatorios/custodia-anual?ano=${formFiltro.ano}&corretora_id=${formFiltro.corretoraId}`)
            .then((resp) => {
                if (resp.data.success) {


                    if (resp.data.results != null) {
                        console.log(resp.data.results);
                        setListDados(resp.data.results)

                    } else {
                        setMsgErro('Sem ativos no dia 31/12')
                        setListDados(null)
                    }

                }
            })
        console.log(formFiltro);


    }


    return (
        <React.Fragment>
            <div className="box-white">
                <h5 className="titulo_pagina">Custódia Anual</h5>
            </div>

            <div className="box-white">

                <Row>

                    <Col sm={4} className="mt-10">
                        <FormLabel>Ano</FormLabel>
                        <Form.Select onChange={(e) => handleChange(e)} name="ano" value={formFiltro.ano}>
                            <option>Selecione...</option>
                            {(listAnos.length > 0) ?
                                listAnos.map((ano) => (
                                    <option value={ano}>{ano}</option>
                                ))
                                : null
                            }

                        </Form.Select>
                    </Col>

                    <Col sm={4} className="mt-10">
                        <FormLabel>Corretora:</FormLabel>
                        <Form.Select onChange={(e) => handleChange(e)} name="corretora" value={formFiltro.corretoraId}>

                            <option value="">Selecione...</option>


                            {optionsCorretora.map((item) => (
                                <option value={item.id}>{item.nome}</option>
                            ))}

                        </Form.Select>
                    </Col>
                </Row>

                {(msgError !== '') ? <Alert variant='danger' size='sm' className="mt-10 text-center">{msgError}</Alert> : ''}
                <Row className="mt-10">
                    <Col sm={12} className="text-center " >
                        <Button variant='primary' size='sm' onClick={() => handleFiltrar()}>Filtrar</Button>
                    </Col>
                </Row>


            </div>




            {(listDados !== null) ? (
                <div className="box-white">
                    <h5 className="text-primary">Ativos</h5>
                    <h6 className='text-default bold'>Ano-Calendário:{formFiltro.ano}</h6>
                    <br></br>
                    <Table bordered striped hover>
                        <thead>
                            <tr>
                                <th>Corretora</th>
                                <th>Ativo</th>
                                <th>Discriminação</th>
                                <th  className=''>Qnt.<br></br> (Até 31/12)</th>
                                <th className=''>PM <br></br>(Até 31/12)</th>
                                <th className=''>Valor em 31/12</th>
                             

                            </tr>
                        </thead>
                        <tbody>
                            {listDados.map((item) => (

                                <tr >
                                    <td>{item.corretora.nome}</td>
                                    <td><b>{item.ativo.codigoNome}</b><br></br>{item.ativo.nome} </td>
                                    <td>{item.discriminacao}</td>
                                    
                                    <td className=''>
                                        
                                        <NumberFormat
                                            isNumericString={true}
                                            className="bold-500"
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={item.casasDecimaisQnt}
                                            thousandSeparator="."
                                            value={item.qntTotal} />
                                    </td>
                                    <td className=''>
                                        
                                        <NumberFormat
                                            isNumericString={true}
                                            className="bold-500"
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={item.casasDecimaisPreco}
                                            thousandSeparator="."
                                            value={item.preco_medio} />
                                    </td>
                                    <td className=''>
                                        
                                        <NumberFormat
                                            isNumericString={true}
                                            className="bold-500"
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={item.casasDecimaisPreco}
                                            thousandSeparator="."
                                            value={item.valorTotal} />
                                    </td>
                                </tr>

                            ))}

                        </tbody>

                    </Table>
                </div>
            ) : ''}


        </React.Fragment>

    )

}

export default CustodiaAnual