import React, { useEffect, useState } from 'react'
import { Col, FormLabel, Row, Table, Field, Form, Alert, Button } from 'react-bootstrap'
import CustomCard from '../../layout/CustomCard'
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead'
import api from '../../../service/api'
import { mesesList } from '../../../assets/utils'
import { dateDBToBR } from '../../../assets/utils'
import NumberFormat from 'react-number-format'
import { date } from 'yup'

const VendasMensais = () => {

    const [listAnos, setListAnos] = useState([])
    const [listDados, setListDados] = useState(null)
    const [msgError, setMsgErro] = useState('')
    const [formFiltro, setFormFiltro] = useState({
        mes: '',
        ano: (new Date().getFullYear()),
        tipoAtivoId: '',
        corretoraId: ''
    })
    const [dadosInfo, setDadosInfo] = useState({
        totalVendas: 0,
        totalPrejuizo: 0,
        totalLucro: 0
    })

    const [optionsCorretora, setOptionsCorretora] = useState([])

    useEffect(() => {
        api.get('/corretoras?movimentacao=true')
            .then((resp) => {
                if (resp.data.success) {
                    const options = resp.data.results.map((item) => ({
                        id: item.id,
                        nome: item.nome
                    }))

                    setOptionsCorretora(options)
                    optionsYears()
                }
            })
    }, [])


    const handleChange = (e) => {


        switch (e.target.name) {

            case 'mes': setFormFiltro({ ...formFiltro, mes: e.target.value })
                break;

            case 'ano': setFormFiltro({ ...formFiltro, ano: e.target.value })
                break;
            case 'corretora': setFormFiltro({ ...formFiltro, corretoraId: e.target.value })
                break;
        }
        setMsgErro('')
    }

    const optionsYears = () => {

        let ano = new Date();
        let anos = [];
        for(let i=ano.getFullYear();i>2000;i--){
            anos.push(i)
        }
        console.log(anos)
        setListAnos(anos)

    }

    const handleFiltrar = () => {

      
        if (formFiltro.ano === '') {
            setMsgErro('O ano deve ser selecionado.')
            return false
        }

        if (formFiltro.corretoraId === '') {
            setMsgErro('A corretora deve ser selecionada.')
            return false
        }

        api.get(`/relatorios/vendas-mensais?mes=${formFiltro.mes}&ano=${formFiltro.ano}&corretora_id=${formFiltro.corretoraId}`)
            .then((resp) => {
                if (resp.data.success) {


                    if (resp.data.results.itens.length > 0) {
                        console.log(resp.data.results);
                        setListDados(resp.data.results.itens)
                        setDadosInfo({
                            ...dadosInfo,
                            totalVendas: resp.data.results.totalVendas,
                            totalLucro: resp.data.results.totalLucro,
                            totalPrejuizo: resp.data.results.totalPrejuizo,
                            totalLiquido: resp.data.results.totalLiquido,
                        })
                    } else {
                        setMsgErro('Sem registros de venda neste mês para esta corretora.')
                        setListDados(null)
                    }

                }
            })
        console.log(formFiltro);


    }


    return (
        <React.Fragment>
            <div className="box-white">
                <h5 className="titulo_pagina">Relatório de vendas mensais</h5>
            </div>

            <div className="box-white">

                <Row>
                    <Col sm={4} className="mt-10">
                        <FormLabel>Mês</FormLabel>

                        <Form.Select onChange={(e) => handleChange(e)} name="mes" value={formFiltro.mes}>
                            <option value="">Todos os meses</option>
                            {mesesList().map((item) => (
                                <option value={item.id} key={item.id}>{item.nome}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col sm={4} className="mt-10">
                        <FormLabel>Ano</FormLabel>
                        <Form.Select onChange={(e) => handleChange(e)} name="ano" value={formFiltro.ano}>
                            <option>Selecione...</option>
                            { (listAnos.length>0)?
                                listAnos.map((ano) =>( 
                                    <option value={ano}>{ano}</option>
                                ))
                            :null
                           }
                      
                        </Form.Select>
                    </Col>

                    <Col sm={4} className="mt-10">
                        <FormLabel>Corretora:</FormLabel>
                        <Form.Select onChange={(e) => handleChange(e)} name="corretora" value={formFiltro.corretoraId}>

                            <option value="">Selecione...</option>


                            {optionsCorretora.map((item) => (
                                <option value={item.id}>{item.nome}</option>
                            ))}

                        </Form.Select>
                    </Col>
                </Row>

                {(msgError !== '') ? <Alert variant='danger' size='sm' className="mt-10 text-center">{msgError}</Alert> : ''}
                <Row className="mt-10">
                    <Col sm={12} className="text-center " >
                        <Button variant='primary' size='sm' onClick={() => handleFiltrar()}>Filtrar</Button>
                    </Col>
                </Row>


            </div>




            {(listDados !== null) ? (
                <div className="box-white">
                    <Row>
                        <Col sm={3}>
                            <CustomCard header="Total de vendas" className='text-center' classNameHeader='text-default bold-500'>
                                <NumberFormat
                                    isNumericString={true}
                                    className='bold-500'
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={8}
                                    thousandSeparator="."
                                    value={dadosInfo.totalVendas}
                                    style={{ fontSize: '2em' }}
                                />
                            </CustomCard>
                        </Col>
                        <Col sm={3}>
                            <CustomCard header="Lucro total" className='text-center' classNameHeader='text-default bold-500'>
                                <NumberFormat
                                    isNumericString={true}
                                    className="bold-500 text-success"
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={8}
                                    thousandSeparator="."
                                    value={dadosInfo.totalLucro}
                                    style={{ fontSize: '2em' }}
                                />
                            </CustomCard>
                        </Col>
                        <Col sm={3}>
                            <CustomCard header="Prejuízo total" className='text-center' classNameHeader='text-default bold-500'>
                                <NumberFormat
                                    isNumericString={true}
                                    className="bold-500 text-danger"
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={8}
                                    thousandSeparator="."
                                    value={dadosInfo.totalPrejuizo}
                                    style={{ fontSize: '2em' }}
                                    title="Para fins de IR, nas criptomodedas conta somente o lucro. O prejuízo não é dedutível" />
                            </CustomCard>
                        </Col>
                        <Col sm={3}>
                            <CustomCard header="Total líquido" className='text-center' classNameHeader='text-default bold-500'>
                                <NumberFormat
                                    isNumericString={true}
                                    className=  {(dadosInfo.totalLiquido>=0)?'text-success bold-500':'text-danger bold-500'   }
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={8}
                                    thousandSeparator="."
                                    value={dadosInfo.totalLiquido} 
                                    style={{ fontSize: '2em' }}
                                    title="Usado somente para Ações" />
                            </CustomCard>
                        </Col>
                    </Row>

                    <hr></hr>
                    <h5 className="text-primary">Movimentos</h5>
                    <Table bordered striped hover>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Ativo</th>
                                <th>Corretora</th>
                                <th>Tipo de ativo</th>
                                <th>Qnt.</th>
                                <th>Total da operacao</th>
                                <th>Lucro/prejuízo</th>

                            </tr>
                        </thead>
                        <tbody>
                            {listDados.map((item) => (
                                <tr className='bold-500'>
                                    <td>{dateDBToBR(item.dataMov)} {item.horaMov}</td>
                                    <td>{item.ativo.nome}({item.ativo.codigo})</td>

                                    <td>{item.corretora.nome}</td>
                                    <td>{item.tipo_ativo.nome}</td>
                                    <td>
                                        <NumberFormat
                                            isNumericString={true}
                                            className='bold-500'
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={item.casasDecimaisQnt}
                                            value={item.qnt}
                                        />
                                    </td>
                                    <td>
                                        <NumberFormat
                                            isNumericString={true}
                                            className='bold-500'
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={item.casasDecimaisPreco}
                                            value={item.totalOperacao}
                                        />
                                    </td>
                                    <td className={(item.lucroPrejuizoVenda > 0) ? 'text-success' : 'text-danger'}>
                                        <NumberFormat
                                            className='bold-500'
                                            isNumericString={true}
                                            displayType="text"
                                            decimalSeparator=","
                                            decimalScale={item.casasDecimaisPreco}
                                            value={item.lucroPrejuizoVenda}
                                        /></td>

                                </tr>
                            ))}

                        </tbody>

                    </Table>
                </div>
            ) : ''}


        </React.Fragment>

    )

}

export default VendasMensais