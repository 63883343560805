import React, { useEffect, useState } from 'react'
import { Nav, NavDropdown, Navbar, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AuthService from '../service/AuthService'

const Header = () => {

  const [user,setUser]  = useState(null);
  useEffect(async() =>{
      if(AuthService.isAutorized){
        
          let me = await AuthService.getMe()
          console.log(me);

      }

  },[])


  return (

    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#">InvestSis</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link to="/" className="nav-link">Dashboard</Link>
            <Link to="/carteiras" className="nav-link">Carteiras</Link>
            <Link to="/corretoras" className="nav-link" >Corretoras</Link>
           <NavDropdown title="Relatórios" id="collasible-nav-dropdown">
              <Link to="/relatorios/vendas-mensais" className="dropdown-item" role="button" tabIndex="0">Vendas Mensais</Link>
              <Link to="/relatorios/custodia-anual" className="dropdown-item" role="button" tabIndex="0">Custódia Anual (IR)</Link>

            </NavDropdown>
            <Link to="/importacoes" className="nav-link" >Importações</Link>
          </Nav>
          
          <Nav>
            { (AuthService.isAutorized()) ?   <Nav.Link href="#deets">Sair</Nav.Link>:''}
         
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>



  )



}

export default Header;