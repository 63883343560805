import React, { useEffect, useState } from 'react'
import { Alert, Table } from 'react-bootstrap';
import api from '../../service/api';
import CorretoraList from './CorretoraList';
import ButtonLink from '../layout/ButtonLink'
import { Button } from 'react-bootstrap'
import CustomModalConfirm from '../layout/CustomModalConfirm';


const Corretora = () => {

    const [listCorretora, setListCorretora] = useState(null);
    const [msgErro, setMsgErro] = useState(null);
    const [idDelete, setIdDelete] = useState(null)

    useEffect(() => {
        api.get('/corretoras')
            .then((resp) => {
                if (resp.data.success) {
                    setListCorretora(resp.data.results)
                }

            })
    }, [])


    const confirmDelete = (id) => {
        setIdDelete(id)
    }

    const handleDelete = () => {

        api.delete('/corretoras/' + idDelete).then((resp) => {
            if (resp.data.success) {
                setIdDelete(null);
                window.location.reload()
            }
        })
    }

    return (
        <React.Fragment>
            <div className="box-white">
                <h5 className="titulo_pagina">Corretoras     <ButtonLink to="corretoras/cad" variant="success">Nova corretora</ButtonLink></h5>
            </div>

            <div className="box-white">

                {(listCorretora != null) ?

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Razão Social</th>
                                <th>CNPJ</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {listCorretora.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.nome}</td>
                                        <td>{item.razao_social}</td>
                                        <td>{item.cnpj}</td>
                                        <td>
                                            <ButtonLink to={`corretoras/edit/${item.id}`} >Editar</ButtonLink>
                                            <Button size="sm" variant="danger" onClick={() => confirmDelete(item.id)}>Excluir</Button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                  
                    </table>
                    : <Alert variant="danger">Nenhuma corretora cadastrada!</Alert>}

            </div>
            <CustomModalConfirm
                show={(idDelete != null)}
                handleConfirm={handleDelete}
                handleClose={() => setIdDelete(null)}
                btnCancel="Não"
                btnConfirm="Sim"

            >
                <div className="alert alert-danger text-center "> Tem certeza que deseja excluir esta corretora?</div>
            </CustomModalConfirm>

        </React.Fragment>
    )


}

export default Corretora
