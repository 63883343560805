import api from "./api"



class AuthService {

    validateLogin = (email, password) => {

        let dados = { email, password }

        return api.post('/login', dados)
            .then((resp) => {
                if (resp.data.token) {
                    localStorage.setItem('token', resp.data.token)
                }
                return resp.data;
            }).catch((error) => {

            })

    }

    getMe = () => {

        return api.get('/me')
            .then((resp) => {
                return resp.data;
            }).catch((error) => {

            })

    }


    logout = () => {
        localStorage.removeItem('token')
    }

    isAutorized = () => {

        return (localStorage.getItem('token') !== null)
    }
    getToken = () => localStorage.getItem('token')


}


export default new AuthService();