import axios from "axios";



const consultaPtax = async (dataCotacao) => {

    var dataC = dataCotacao.split('-');
    dataC = dataC[1] + '-' + dataC[2] + '-' + dataC[0]
    dataC = new Date(dataC)
    console.log(dataCotacao)
    console.log(dataC)  
    var fimSemana = false;
    //Regra para pegar a cotação de sexta caso seja final de semana
    if (dataC.getDay() == 0) { //domingo

        dataC = dataC.getTime() - ((2 * 86400) * 1000)
        dataC = new Date(dataC)
        fimSemana = true

    } else if (dataC.getDay() == 6) { //sabado
        dataC = dataC.getTime() - (86400)
        dataC = new Date(dataC)
        fimSemana = true
    }

    var date = (dataC.getDate() < 10) ? '0' + dataC.getDate() : dataC.getDate();
    var month = dataC.getMonth() + 1
    month = (month < 10) ? '0' + month : month;


    dataC = month + '-' + date + '-' + dataC.getFullYear()

    let result = null;

    // const response = await fetch()

    const responseAxios = await axios.get(`https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao='${dataC}'&$top=100&$format=json&$select=cotacaoCompra,cotacaoVenda`)
     
    

    return await responseAxios
}

export default consultaPtax;