import React from 'react'
import { Card } from 'react-bootstrap'


const CustomCard = (props) => {

    return (
        <React.Fragment>

            <Card 
            className={props.className}
            style={props.style}
            bg={props.bg}
            >
                <Card.Header style={{fontSize:'1.2em'}} className='bold' className={props.classNameHeader}>
                    {props.header}
                </Card.Header>
                <Card.Body style={{padding:'2px'}} className={props.className}>
                    {(props.title !== undefined) ? <Card.Title>{props.title}</Card.Title> : ''}
                    <Card.Text>{props.children}</Card.Text>
                </Card.Body>
            </Card>

        </React.Fragment>
    )


}

export default CustomCard