import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Formik, Field, Form as FormikForm } from 'formik'
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import ButtonLink from '../layout/ButtonLink'
import api from '../../service/api';




const CarteiraCadEdit = () => {

    let history = useHistory();
    let { id } = useParams();
    let titulo = (id != null) ? 'Editar' : 'Cadastrar';

    const [msgErro, setMsgErro] = useState(null);
    const [show, setShow] = useState(false)
    const [carteira, setCarteira] = useState({
        id: null,
        nome: ''
    })

    useEffect(() => {
        console.log(id);
        if (id !== undefined) {
            api.get(`/carteiras/${id}`)
                .then((resp) => {
                    if (resp.data.success) {
                        setCarteira(resp.data.results)
                    }
                })
        }
    }, [])


    const handleSubmit = (values, actions) => {
        console.log(values);


        const dados = { nome: values.nome }
        if (carteira.id != null) {
            api.put('/carteiras/' + carteira.id, dados)
                .then((resp) => {
                    if (resp.data.success) {
                        history.push('/carteiras')

                    }
                }).error((errors) => {


                })
        } else {

            api.post('/carteiras', dados)
                .then((resp) => {
                    if (resp.data.success) {
                        history.push('/carteiras')

                    }
                }).error((errors) => {


                })
        }
    }

    const validate = (values) => {
        const errors = {}
        if (values.nome == '') {
            errors.nome = 'O nome da carteira deve ser preenchido'
        }
        return errors;
    }
    return (
        <div>
            <div class="box-white">
            <h5 className="titulo_pagina">Carteira - {titulo}</h5>
            </div>
            <div class="box-white">
                <br />
                <Formik
                    validate={validate}  ///pose uasr o validateSchema com o plugin Yup
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    initialValues={carteira}

                    render={({ values, errors }) => (
                        <FormikForm >
                            <Row>
                                <Col sm={4}>
                                    <Form.Label>Nome:</Form.Label>
                                    <Field type="text" name="nome" className="form-control input-sm" />

                                    {errors.nome && (

                                        <span>{errors.nome}</span>
                                    )}
                                </Col>
                            </Row>
                            <br />

                            {(msgErro != null) ? <Alert variant="danger">{msgErro}</Alert> : ''}
                            <Row>
                                <Col sm={4}>
                                    <ButtonLink to="/carteiras" variant="danger">Cancelar</ButtonLink>
                                    <Button type="submit" size='sm' >Enviar</Button>
                                </Col>

                            </Row>
                        </FormikForm>
                    )}
                >

                </Formik>
            </div>


        </div>
    )
}

export default CarteiraCadEdit