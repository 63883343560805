import React, { useEffect, useState } from 'react'
import api from '../../../service/api'
import { Alert, Button } from 'react-bootstrap'
import styled from 'styled-components'
import CustomModal from '../../layout/CustomModal'
import CarteiraAtivoMovimentacoes from './CarteiraAtivoMovimentacoes'
import CarteiraCadMov from './CarteiraCadMov'
import { currencyFormatter, currencyFormatter2 } from '../../../assets/utils'


const DivRow = styled.div`
display:flex;
background: #f3f9ff;
//border-left: 3px solid #a3b2d4;
//border-top: 1px solid #a3b2d4;
border-bottom: 1px solid #a3b2d4;
//border-right: 1px solid #a3b2d4;
//margin-bottom:5px;
font-size:12px;
flex-direction:row;
flex-wrap:wrap;
border-radius: 8px;

`

const DivColumn = styled.div`
flex:1;
padding:5px;
font-weight:bold;
color:#636363;
margin-bottom:10px;
min-width:100px;

@media(max-width: 900px){
  
}

.title{
    font-weight: initial;
    width: 100%;
    maring-bottom: 4px;
    color: #4b4848;
    font-size: 0.9em;
}
.txtContent{
    font-weight: 500;
}
.txtContentSmall{
    font-weight: 400;
    font-size: 0.9em;
}
a{
    text-decoration:none;
}


`


const CarteiraListAtivos = (props) => {

    const [posicao, setPosicao] = useState('')
    const [ativosList, setAtivosList] = useState(null)
    const [msgErro, setMsgErro] = useState(null)
    const [idCarteiraItemSelect, setIdCarteiraItemSelect] = useState(null)
    const [showListAtivo, setShowListAtivo] = useState(true)
    const [showListMov, setListMov] = useState(false)
    const [tipoMovForm, setTipoMovForm] = useState(null)
    const [ordenacao, setOrdenacao] = useState(null)
    const [ordem, setOrdem] = useState(null)

    const [cadMovVisible, setCadMovVisible] = useState(false)
    const handleModalMovOpen = () => setCadMovVisible(true)
    const handleModalMovClose = () => {
        setCadMovVisible(false)
        setIdCarteiraItemSelect(null)
    }




    const refreshCarteiraItens = (idCarteira, posicao = '') => {

        setPosicao(posicao)
        let ordenacaoString = '';
        if (ordenacao != null) {
            ordenacaoString = '&sort=' + ordenacao
            ordenacaoString += (ordem != null) ? ':' + ordem : '';
        }

        api.get(`/carteiras/${idCarteira}/itens` + '?posicao=' + posicao + ordenacaoString)
            .then((resp) => {
                if (resp.data.success) {
                    setAtivosList(resp.data.results)
                } else {
                    setMsgErro('Nenhum ativo adicionado')
                }
            }).catch((error) => {
                setAtivosList(null)
                setMsgErro('Nenhum ativo adicionado')
            })

    }
    useEffect(() => {
        refreshCarteiraItens(props.idCarteira)
    }, [props.idCarteira,ordem,ordenacao])


    const handleBtnMovimentacoes = (idItemCarteira) => {
        setIdCarteiraItemSelect(idItemCarteira);
        alternateDivs('movimentos')
    }



    const alternateDivs = (divExibicao) => {

        setListMov(false)
        setShowListAtivo(false)
        switch (divExibicao) {
            case 'ativos': setShowListAtivo(true)
                setIdCarteiraItemSelect(null)
                refreshCarteiraItens(props.idCarteira)
                break;
            case 'movimentos': setListMov(true)
                break;
        }

    }


    const handleMovCompra = (idCarteiraItem) => {
        setTipoMovForm(1);
        setIdCarteiraItemSelect(idCarteiraItem)
        setCadMovVisible(true)
    }

    const handleMovVenda = (idCarteiraItem) => {
        setTipoMovForm(2);
        setIdCarteiraItemSelect(idCarteiraItem)
        setCadMovVisible(true)
    }
    const optOrdenacao = [
        { campo: 'codAtivo', nome: 'Cód. do Ativo' },
        { campo: 'custoAquisicao', nome: 'Custo de Aquisição' },
        { campo: 'precoAtivo', nome: 'Cotação' },
        { campo: 'valorTotalAtual', nome: 'Valor Total Atual' },
    ]

    const handleOrdenacao = (e) => {

        switch (e.target.name) {
            case 'ordemCampo': setOrdenacao(e.target.value)
                break;
            case 'ordem': setOrdem(e.target.value)
                break;
        }
        //refreshCarteiraItens(props.idCarteira)
    }
    return (
        <React.Fragment>

            <div style={{ display: (!showListAtivo) ? 'none' : '' }}>


                <Button size='sm' onClick={handleModalMovOpen} style={{ float: 'left' }}>Lançar Movimento</Button>


                <div style={{ float: 'right' }}>
                    <label >Status da Posição: </label>
                    <input type='radio' className='ml-15' checked={posicao == '' ? true : false} name="posicao" value='' id="pos1" onChange={(e) => refreshCarteiraItens(props.idCarteira, e.target.value)} /> <label for="pos1" className='bold-500'> Todos</label>
                    <input type='radio' className='ml-15' checked={posicao == 'ativa' ? true : false} name="posicao" value='ativa' id="pos2" onChange={(e) => refreshCarteiraItens(props.idCarteira, e.target.value)} /> <label for="pos2" className='bold-500'> Ativa</label>
                    <input type='radio' className='ml-15' checked={posicao == 'zerada' ? true : false} name="posicao" value='zerada' id="pos3" onChange={(e) => refreshCarteiraItens(props.idCarteira, e.target.value)} /> <label for="pos3" className='bold-500'> Zerada</label>
                </div>

                <div style={{ clear: 'both' }}></div>

                <div style={{ float: 'right' }}>
                    <label style={{ marginRight: '5px' }}>Ordernar por: </label>

                    <select name='ordemCampo' className='input-sm' style={{ marginRight: '5px' }} onChange={handleOrdenacao}>
                        <option>Selecione...</option>
                        {(optOrdenacao).map(item => (
                            <option value={item.campo}>{item.nome}</option>
                        ))
                        }
                    </select>
                    <select name='ordem' className='input-sm' style={{ marginRight: '5px' }} onChange={handleOrdenacao}>
                        <option value='asc'>ASC</option>
                        <option value='desc'>DESC</option>
                    </select>

                </div>


                <div style={{ clear: 'both' }}></div>
                <br></br>

                <DivRow >
                    <DivColumn className='text-center'></DivColumn>

                    <DivColumn>
                        <div className="title" title="Preço médio/Custo de aquisição">Preço Médico </div>
                        <div className="title" title="Preço médio/Custo de aquisição">Custo de aquisição</div>
                    </DivColumn>
                    <DivColumn>
                        <div className="title" title="Cotação Atual atualizada a cada 15 minutos">Cotação Atual</div>

                    </DivColumn>
                    <DivColumn>
                        <div className="title" title="Lucro/prejuizo">Lucro/Prejuízo</div>
                        <div className="title" title="Valor Total Atual">Valor Total Atual</div>
                    </DivColumn>
                    <DivColumn></DivColumn>
                    <DivColumn></DivColumn>
                </DivRow>
                {(ativosList != null) ?
                    ativosList.map((item) => (
                        <div>

                            <DivRow >
                                <DivColumn className='text-center'>
                                    <span title='Ativo' className='text-primary bold'>  {item.ativos_codigo_nome}</span><br></br>
                                    <span className='txtContent' title='Quantidade'>{item.qnt_total}</span>
                                </DivColumn>

                                <DivColumn>
                                    <span className='txtContent'>R$   {item.preco_medio}</span><br></br>
                                    <span className='txtContentSmall' title='Custo de Aquisição'>{
                                        '=' + currencyFormatter2(item.custoAquisicao, 8)}</span>
                                </DivColumn>

                                <DivColumn>
                                    <span className='txtContent'>{(item.cotacao != null) ?
                                        currencyFormatter2(item.cotacao.precoAtivo, 8) : ''}</span><br></br>


                                </DivColumn>
                                <DivColumn>

                                    {(item.cotacao != null) ? (
                                        <>
                                            <span className='txtContent'
                                                class={(item.cotacao.lucroPrejuizoValor >= 0) ? 'text-success' : 'text-danger'}
                                                title={`Valorização Percentual: ${item.cotacao.valorizacaoPercentual}% / Valorização Qnt: ${item.cotacao.valorizacaoVezes}x`}
                                            >{currencyFormatter2(item.cotacao.lucroPrejuizoValor, 8)}</span><br></br>
                                            <span className='txtContentSmall' title='Valor total atual'>{(item.cotacao != null) ?
                                                '=' + currencyFormatter2(item.cotacao.valorTotalAtual, 8) : ''}</span>

                                        </>
                                    ) : ''}
                                </DivColumn>
                                <DivColumn>
                                    <a href="#" onClick={() => handleBtnMovimentacoes(item.id)}>Movimentações</a>
                                </DivColumn>
                                <DivColumn>
                                    <Button variant="info" size="sm" onClick={() => handleMovCompra(item.id)}>+</Button>
                                </DivColumn>
                            </DivRow>
                        </div>

                    ))
                    : <Alert variant='danger'>{msgErro}</Alert>}
            </div>

            <div style={{ display: (!showListMov) ? 'none' : '' }}>

                <CarteiraAtivoMovimentacoes
                    idCarteira={props.idCarteira}
                    idItemCarteira={idCarteiraItemSelect} />
                <div className='text-center mt-15'>
                    <button className="btn btn-sm btn-danger" onClick={() => alternateDivs('ativos')}>Fechar</button>
                </div>
            </div>


            <CustomModal
                show={cadMovVisible}
                handleClose={handleModalMovClose}
            >
                <CarteiraCadMov
                    idCarteira={props.idCarteira}
                    handleCancel={handleModalMovClose}
                    refreshCarteiraItens={refreshCarteiraItens}
                    tipoMovForm={tipoMovForm}
                    idCarteiraItem={idCarteiraItemSelect}
                ></CarteiraCadMov>
            </CustomModal>

        </React.Fragment>
    )
}

export default CarteiraListAtivos