import axios from 'axios'
import AuthService from './AuthService';

const API_URL = process.env.REACT_APP_API_URL_SISTEMA;
const api = axios.create({
    baseURL: API_URL
})

api.interceptors.response.use(resp => {
  
    return  resp

}, error => {
   
    if (error.response.status == 401 && error.response.data == 'Unauthorized.') {      
        localStorage.removeItem('token')
    }
    return Promise.reject(error);
})


api.interceptors.request.use(async config => {
    const token = AuthService.getToken()

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
})

export default api;