import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap'


const CustomModal = (props) => {

    return (
        <Modal
            show={props.show}    
            size={props.size}
            >
            <ModalBody>
                {props.children}
            </ModalBody>          
        </Modal>
    )

}
export default CustomModal