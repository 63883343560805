import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ButtonLink = (props) => {


    return (
        <Link to={props.to} >
            <Button variant={props.variant} size="sm" onChange={props.onChange} as={props.as}
            style={{marginLeft:'3px',marginRight:'3px'}}
            >{props.children}</Button>
        </Link >
    )
}

export default ButtonLink