
import React, { useEffect, useState } from 'react'
import { Col, FormLabel, Row, Button, Alert } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { useParams, useHistory } from 'react-router-dom'
import ButtonLink from '../layout/ButtonLink'
import { Formik, Form as FormikForm, Field } from 'formik'
import * as Yup from 'yup'
import api from '../../service/api'

const CorretoraCadEdit = () => {

    let { id } = useParams()
    let history = useHistory()
    const [msgErro, setMsgErro] = useState('')

    const [corretora, setCorretora] = useState({
        id: '',
        nome: '',
        cnpj: '',
        razao_social: ''
    })



    useEffect(() => {
        if (id != null) {
            api.get('/corretoras/' + id).then((resp) => {

                if (resp.data.success) {
                    setCorretora({
                        id: resp.data.results.id,
                        nome: resp.data.results.nome,
                        cnpj: (resp.data.results.cnpj == null) ? '' : resp.data.results.cnpj,
                        razao_social: resp.data.results.razao_social,
                    }
                    )
                } else {
                    history.push('/corretoras')
                }

                console.log(resp.data.results);
            })
        }
    }, [])



    const CorretoraSchema = Yup.object().shape({
        nome: Yup.string().min(2).max(255).required('Este campo é obrigatório'),
    })
    const handleSubmit = (values, actions) => {

        console.log(values);


        if (id == null) {
            api.post('corretoras', values)
                .then((resp) => {
                    history.push('/corretoras')

                }).catch((er) => {


                    if (er.response.data.message == "CNPJ inválido") {
                        actions.setErrors({ cnpj: er.response.data.message })
                    }
                })
        } else {
            api.put('/corretoras/' + id, values).then((resp => {

                history.push('/corretoras')
            }))
                .catch((er) => {
                    if (er.response.data.message == "CNPJ inválido") {
                        actions.setErrors({ cnpj: er.response.data.message })
                    }
                })
        }


    }

    return (
        <React.Fragment>
            <br></br>
            <div class="box-white">
                <h5 className="titulo_pagina"> Corretora - {(id != null) ? 'Editar' : 'Cadastrar'}</h5>
            </div>
            <div class="box-white">
                <Formik
                    enableReinitialize={true}
                    initialValues={corretora}
                    validationSchema={CorretoraSchema}
                    onSubmit={handleSubmit}

                    render={({ values, errors, setFieldValue }) => (
                        <FormikForm>

                            <Row>
                                <Col sm={6} className="mt-10">
                                    <FormLabel>Nome:</FormLabel>
                                    <Field id="nome" name="nome" className="form-control" />
                                    {(errors.nome) ? (<div className="text-danger">{errors.nome}</div>)
                                        : null}
                                </Col>
                                <Col sm={6} className="mt-10">
                                    <FormLabel>Cnpj:</FormLabel>
                                    <NumberFormat
                                        name="cnpj"
                                        className="form-control"
                                        format="##.###.###/####-##"
                                        onValueChange={(value) => setFieldValue('cnpj', value.value)}
                                    />
                                    {(errors.cnpj) ? (<div className="text-danger">{errors.cnpj}</div>)
                                        : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} className="mt-10">
                                    <FormLabel>Razao Social</FormLabel>
                                    <Field id="razao_social" name="razao_social" className="form-control" />
                                </Col>
                            </Row>
                            <div className="clearfix"></div>

                            {(msgErro != '') ? (<Alert variant="danger" className="mt-10">{msgErro}</Alert>) : null}

                            <div className="text-center mt-20">
                                <ButtonLink to="/corretoras" variant="danger">Voltar</ButtonLink>
                                <Button size='sm' variant="primary" className="ml-5" type="submit">Salvar</Button>
                            </div>
                        </FormikForm>

                    )}
                >

                </Formik>
            </div>


        </React.Fragment>


    )

}

export default CorretoraCadEdit