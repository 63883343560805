import React, { useEffect, useState } from 'react';
import { dateDBToBR } from '../../../assets/utils';
import NumberFormat from 'react-number-format';
import api from '../../../service/api';
import styled from 'styled-components';
import { Table, Button } from 'react-bootstrap';
import MovimentoService from '../../../service/MovimentoService';



const DivHeader = styled.div`
margin-bottom:15px;
`




const CarteiraAtivoMovimentacoes = (props) => {


    const [movimentosList, setMovimentosList] = useState(null);
    const [dadosItem, setDadosItem] = useState(null)

    const resfreshMovimentos = (idItemCarteira, idCarteira) => {

        console.log(idItemCarteira)
        if (idItemCarteira != null) {
            api.get('/carteiras/' + idCarteira + '/itens/' + idItemCarteira)
                .then((resp) => {
                    if (resp.data.success) {
                        setDadosItem(resp.data.results)
                    } else {
                        setDadosItem(null)
                    }
                }).catch((error) => {
                    console.log(error);
                })
            api.get('/carteiras/' + idCarteira + '/itens/' + idItemCarteira + '/movimentos')
                .then((resp) => {
                    if (resp.data.success) {
                        setMovimentosList(resp.data.results)
                    } else {
                        setMovimentosList(null)
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }

    }

    useEffect(() => {

        setMovimentosList(null)
        resfreshMovimentos(props.idItemCarteira, props.idCarteira)


    }, [props.idItemCarteira])


    const handleDeleteMovimento = async (idMovimento) => {

        /* const deletar = await MovimentoService.delete(idMovimento)
     console.log(deletar)
       if (deletar.success) {
           resfreshMovimentos()
       }*/
    }

    return (
        <React.Fragment>





            {(dadosItem != null) ? (


                <DivHeader>
                    <h5 className="titulo_pagina">Movimentações - {dadosItem.ativo_nome} ({dadosItem.ativos_codigo_nome})</h5>
                    <hr></hr>
                    <div style={{ fontSize: '14px' }}>

                        <label>Qnt.: </label > {dadosItem.qnt_total}<br></br>
                        <label>Preço médio: </label > {dadosItem.preco_medio}<br></br>
                    </div>
                </DivHeader>
            ) : ''}






            {(movimentosList != null) ?
                <div style={{ fontSize: '14px' }}>
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Hora</th>
                                    <th>Tipo</th>
                                    <th>Par</th>
                                    <th>Qnt</th>
                                    <th>Valor Unit.(R$)</th>
                                    <th>Taxas</th>
                                    <th title="Preço médio">PM (R$)</th>
                                    <th>Total (R$)</th>
                                    <th>Lucro/Prejuízo (R$)</th>

                                    <th>Comprovante</th>
                                    <th>Observações</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {movimentosList.map((item, index) => (


                                    <tr >

                                        <td>{dateDBToBR(item.data_movimento)}</td>
                                        <td>{item.hora_movimento}</td>
                                        <td >
                                            <b className={(item.tipoCompraVenda == 2) ? 'color-venda' : 'color-compra'
                                            }>{item.nomeTipoMovimento}</b>


                                            {(item.zerouPosicao == 1) ?
                                                <div style={{ color: 'red', fontWeight: 'bold', fontSize: '11px' }}>Zerou a posição</div> : ''}

                                            {(item.tipo_movimento_id == 6 || item.tipo_movimento_id == 7) ?
                                                <div style={{ color: '#6a6a6a', fontWeight: 'bold', fontSize: '11px' }}>
                                                    <label>De:</label> {item.grupDesdDe}<br></br>
                                                    <label>Para:</label> {item.grupDesdPara}<br></br>

                                                </div> : ''}
                                        </td>
                                        <td>
                                            {(item.ativoPar != null) ?
                                                <div >

                                                    <b>{item.codParidade}</b><br></br>
                                                   
                                                    <div >
                                                    {(item.ativoPar.parAtivosCodigoId != 906) ?
                                                        <div>
                                                            <b>{item.ativoPar.nomeCodAtivoPar} </b>

                                                            <div style={{ fontSize: 12 }}>
                                                                <label title='Cotação do dólar' style={{ marginTop: 10, marginRight: 5 }}>Cotação de fechamento do dólar:  </label> <br></br>
                                                                <NumberFormat
                                                                    decimalScale={item.casas_decimais_preco}
                                                                    decimalSeparator=","
                                                                    value={item.ativoPar.parCotacaoDolar}
                                                                    displayType='text'
                                                                    isNumericString={true}
                                                                    thousandSeparator="."
                                                                />
                                                                <br></br>

                                                                {(item.ativoPar.parAtivosCodigoId != 807) ?
                                                                    <div> <label title={`Valor ${item.ativoPar.nomeCodAtivoPar} em dólar`} style={{ marginTop: 10, marginRight: 5 }}>Valor {item.ativoPar.nomeCodAtivoPar} em dólar:  </label> <br></br>
                                                                        <NumberFormat
                                                                            decimalScale={item.casas_decimais_preco}
                                                                            decimalSeparator=","
                                                                            value={item.ativoPar.parValorDolar}
                                                                            displayType='text'
                                                                            isNumericString={true}
                                                                            thousandSeparator="."
                                                                        />

                                                                        <br></br>
                                                                    </div>

                                                                    : ''
                                                                }


                                                                <label style={{ marginTop: 10, marginRight: 5 }}>Qnt. {item.ativoPar.nomeCodAtivoPar}: </label>
                                                                <NumberFormat
                                                                    decimalScale={item.casas_decimais_preco}
                                                                    decimalSeparator=","
                                                                    value={item.ativoPar.parQnt}
                                                                    displayType='text'
                                                                    isNumericString={true}
                                                                    thousandSeparator="."
                                                                />
                                                                <br></br>
                                                                <label style={{ marginTop: 10, marginRight: 5 }}>Total em R$: </label>
                                                                <NumberFormat
                                                                    decimalScale={item.casas_decimais_preco}
                                                                    decimalSeparator=","
                                                                    value={item.ativoPar.parValorTotalBrl}
                                                                    displayType='text'
                                                                    isNumericString={true}
                                                                    thousandSeparator="."
                                                                />


                                                            </div>
                                                        </div> : ''}
                                                        </div>
                                                </div>
                                                : ''}
                                        </td>
                                        <td>



                                            <NumberFormat
                                                decimalScale={item.casas_decimais_preco}
                                                decimalSeparator=","
                                                value={item.qnt}
                                                displayType='text'
                                                isNumericString={true}
                                                thousandSeparator="."
                                            /><br></br>
                                            {(item.taxa_rede != null) ? (
                                                <div style={{fontSize:'11px'}}>
                                                    <br></br>
                                                    <span style={{fontWeight:'bold', color: '#605f5f'}}>Qnt. liq.: </span>
                                                    <NumberFormat
                                                        decimalScale={item.casas_decimais_preco}
                                                        decimalSeparator=","
                                                        value={item.qnt - item.taxa_rede}
                                                        displayType='text'
                                                        isNumericString={true}
                                                        thousandSeparator="."
                                                    />
                                                </div>

                                            ) : ''}
                                        </td>
                                        <td>
                                            <NumberFormat
                                                decimalScale={item.casas_decimais_preco}
                                                decimalSeparator=","
                                                value={item.valor_unitario}
                                                displayType='text'
                                                isNumericString={true}
                                                thousandSeparator="."
                                            />
                                        </td>
                                        <td>
                                            {(item.emolumentos > 0) ?
                                                <div>

                                                    <b>Emolumentos: </b>

                                                    <NumberFormat
                                                        decimalScale={item.casas_decimais_preco}
                                                        decimalSeparator=","
                                                        value={item.emolumentos}
                                                        displayType='text'
                                                        isNumericString={true}
                                                        thousandSeparator="."
                                                    />

                                                    <br></br>
                                                </div>
                                                : ''}
                                            {(item.corretagem > 0) ?
                                                <div>
                                                    <b>Corretagem: </b>

                                                    <NumberFormat
                                                        decimalScale={item.casas_decimais_preco}
                                                        decimalSeparator=","
                                                        value={item.corretagem}
                                                        displayType='text'
                                                        isNumericString={true}
                                                        thousandSeparator="."
                                                    />

                                                    <br></br>
                                                </div>
                                                : ''}

                                            {(item.taxa_extra > 0) ?
                                                <div>
                                                    <b>Taxas extras: </b>
                                                    <NumberFormat
                                                        decimalScale={item.casas_decimais_preco}
                                                        decimalSeparator=","
                                                        value={item.taxa_extra}
                                                        displayType='text'
                                                        isNumericString={true}
                                                        thousandSeparator="."
                                                    />
                                                    <br></br>
                                                </div>
                                                : ''}
                                            {(item.taxa_rede > 0) ?
                                                <div>
                                                    <b>Taxas de rede: </b><br></br>
                                                    <NumberFormat
                                                        decimalScale={item.casas_decimais_preco}
                                                        decimalSeparator=","
                                                        value={item.taxa_rede}
                                                        displayType='text'
                                                        isNumericString={true}
                                                        thousandSeparator="."
                                                    />
                                                    <br></br>
                                                </div>
                                                : ''}

                                            {(item.taxaCriptoAtivoCodId != null && item.taxaCriptQnt != null) ?
                                                <div>
                                                    <b>Taxa ({item.taxaCriptNomeCod}): </b>
                                                    <NumberFormat
                                                        decimalScale={item.casas_decimais_preco}
                                                        decimalSeparator=","
                                                        value={item.taxaCriptQnt}
                                                        displayType='text'
                                                        isNumericString={true}
                                                        thousandSeparator="."
                                                    />

                                                    <br></br>
                                                </div>
                                                : ''}



                                        </td>
                                        <td>
                                            <NumberFormat
                                                decimalScale={item.casas_decimais_preco}
                                                decimalSeparator=","
                                                value={item.preco_medio}
                                                displayType='text'
                                                isNumericString={true}
                                                thousandSeparator="."
                                            /></td>
                                        <td>
                                            <NumberFormat
                                                decimalScale={item.casas_decimais_preco}
                                                decimalSeparator=","
                                                value={item.total_operacao}
                                                displayType='text'
                                                isNumericString={true}
                                                thousandSeparator="."
                                            />
                                        </td>
                                        <td>

                                            {((item.lucro_prejuizo_venda_liquido) != 0) ? (
                                                <NumberFormat
                                                    decimalSeparator=","
                                                    decimalScale={item.casas_decimais_preco}
                                                    value={item.lucro_prejuizo_venda_liquido}
                                                    isNumericString={true}
                                                    displayType='text'
                                                    thousandSeparator="."

                                                />
                                            ) : ''}
                                        </td>

                                        <td>
                                            {(item.comprovantes != null) ?
                                                <a href={item.comprovantes[0].url} target="_blank">Download</a>
                                                : ''}

                                        </td>
                                        <td>{item.observacoes}</td>
                                        <td>
                                            <Button variant="danger" size='sm'
                                                style={{ display: ((index + 1) == movimentosList.length) ? '' : 'none' }}
                                                onClick={() => handleDeleteMovimento(item.id)}>Excluir</Button>
                                        </td>
                                    </tr>


                                ))}
                            </tbody>

                        </Table>
                    </div>

                </div>

                :
                <div>Sem movimentos para este ativo</div>

            }






        </React.Fragment>
    )


}

export default CarteiraAtivoMovimentacoes;