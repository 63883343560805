import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'


const Importacao = () => {



    return (
        <React.Fragment>
            <div className="box-white">
                <h5 className="titulo_pagina">Importações</h5>

                <Row>
                    <Col sm={4}>
                        <Form.Label>Corretora</Form.Label>
                        <Form.Select  name="mes" >
                            <option value="">Todos os meses</option>
                         
                        </Form.Select>
                    </Col>
                </Row>

            </div>



        </React.Fragment>
    )






}

export default Importacao