import React from 'react'
import { Modal, ModalBody, Button } from 'react-bootstrap'


const CustomModalConfirm = (props) => {

    return (
        <Modal
            show={props.show}        >
            <ModalBody>
                {props.children}
            </ModalBody>
            <Modal.Footer>
                <Button variant="danger" size='sm' onClick={props.handleClose}>{props.btnCancel}</Button>
                
                <Button variant="primary" size='sm' onClick={props.handleConfirm}>{props.btnConfirm}</Button>
                </Modal.Footer>       
        </Modal>
    )

}
export default CustomModalConfirm