import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/customCss.css'
import Header from './template/Header'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Carteira from './componentes/Carteiras/Carteira';
import Dashboard from './componentes/Dashboard';
import CarteiraCadEdit from './componentes/Carteiras/CarteiraCadEdit';
import CarteiraGerenciamento from './componentes/Carteiras/Gerenciamento/CarteiraGerenciamento';
import Corretora from './componentes/Corretoras/Corretora';
import CorretoraCadEdit from './componentes/Corretoras/CorretoraCadEdit';
import VendasMensais from './componentes/Relatorios/VendaMensais/VendasMensais';
import Login from './componentes/Login';
import AuthService from './service/AuthService';
import CustodiaAnual from './componentes/Relatorios/CustodiaAnual';
import Importacao from './componentes/Importacoes/Importacao';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      AuthService.isAutorized() ? (
        <Component {...props}></Component>
      ) : <Redirect to={{ pathname: '/login', state: { form: props.location } }} />
    }
  ></Route>

)

function App() {
  return (
    <BrowserRouter>
      <React.Fragment>

        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register">
          <Login />
        </Route>


        <Header />
        <main className="container">
          <Switch>


            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/carteiras" component={Carteira} />
            <PrivateRoute exact path="/carteiras/cad" component={CarteiraCadEdit} />
            <PrivateRoute exact path="/carteiras/edit/:id" component={CarteiraCadEdit} />
            <PrivateRoute exact path="/carteiras/:id" component={CarteiraGerenciamento} />
            <PrivateRoute exact path="/corretoras" component={Corretora} />
            <PrivateRoute exact path="/corretoras/cad" component={CorretoraCadEdit} />

            <PrivateRoute exact path="/corretoras/edit/:id" component={CorretoraCadEdit} />
            <PrivateRoute exact path="/relatorios/vendas-mensais" component={VendasMensais} />
            <PrivateRoute exact path="/relatorios/custodia-anual" component={CustodiaAnual} />
            <PrivateRoute exact path="/importacoes" component={Importacao}/>

          </Switch>
        </main>


      </React.Fragment>



    </BrowserRouter>


  );
}

export default App;
