import React from 'react'
import CustomCard from './layout/CustomCard'


const Dashboard = () => {


    return (
        <React.Fragment>
            <div className="box-white">
            <h5 className="titulo_pagina">Dashboard</h5>
 
               
            </div>
        </React.Fragment>
    )


}

export default Dashboard